<template>
    <div class="list-view">
        <page-title>
            <el-button
                @click="addRow"
                type="primary"
                icon="el-icon-plus"
                :disabled="fetchingData || downloading"
                class="filter-item"
            >
                新增
            </el-button>
            <!-- <el-button
                @click="download"
                icon="el-icon-upload2"
                :loading="downloading"
                :disabled="fetchingData"
                class="filter-item"
            >
                导出
            </el-button> -->
        </page-title>
        <div class="filters-container">
            <created-at-picker v-model="expiration" @input="initData" name="到期"></created-at-picker>
            <el-input
                placeholder="搜索..."
                v-model="search"
                clearable
                class="filter-item search"
                @keyup.enter.native="initData"
            >
                <el-button @click="initData" slot="append" icon="el-icon-search"> </el-button>
            </el-input>
        </div>
        <el-table
            :data="tableData"
            row-key="id"
            ref="table"
            header-row-class-name="table-header-row"
            header-cell-class-name="table-header-cell"
            row-class-name="table-row"
            cell-class-name="table-cell"
            :height="tableHeight"
            v-loading="fetchingData"
        >
            <el-table-column v-if="multipleMode" align="center" type="selection" width="50"> </el-table-column>
            <el-table-column prop="id" label="ID" width="100"> </el-table-column>
            <el-table-column prop="name" label="名称"> </el-table-column>
            <el-table-column prop="remark" label="备注" show-overflow-tooltip> </el-table-column>
            <el-table-column prop="limited" label="指定藏品">
                <template slot-scope="{ row }">
                    <el-tag v-if="row.limited" type="success">是</el-tag>
                    <el-tag v-else type="info">否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="needGas" label="需要支付GAS">
                <template slot-scope="{ row }">
                    <el-tag v-if="row.needGas" type="success">是</el-tag>
                    <el-tag v-else type="info">否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="expiration" label="到期时间"> </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="150">
                <template slot-scope="{ row }">
                    <el-button @click="editRow(row)" type="primary" size="mini" plain>编辑</el-button>
                    <el-button @click="deleteRow(row)" type="danger" size="mini" plain>删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-wrapper">
            <!-- <div class="multiple-mode-wrapper">
                <el-button v-if="!multipleMode" @click="toggleMultipleMode(true)">批量编辑</el-button>
                <el-button-group v-else>
                    <el-button @click="operation1">批量操作1</el-button>
                    <el-button @click="operation2">批量操作2</el-button>
                    <el-button @click="toggleMultipleMode(false)">取消</el-button>
                </el-button-group>
            </div> -->
            <el-pagination
                background
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import pageableTable from '@/mixins/pageableTable';

export default {
    name: 'CouponList',
    mixins: [pageableTable],
    data() {
        return {
            multipleMode: false,
            search: '',
            url: '/coupon/all',
            downloading: false,
            expiration: ''
        };
    },
    computed: {
        selection() {
            return this.$refs.table.selection.map(i => i.id);
        }
    },
    methods: {
        beforeGetData() {
            return { search: this.search, query: { del: false, expiration: this.expiration } };
        },
        toggleMultipleMode(multipleMode) {
            this.multipleMode = multipleMode;
            if (!multipleMode) {
                this.$refs.table.clearSelection();
            }
        },
        addRow() {
            this.$router.push({
                path: '/couponEdit',
                query: {
                    ...this.$route.query
                }
            });
        },
        editRow(row) {
            this.$router.push({
                path: '/couponEdit',
                query: {
                    id: row.id
                }
            });
        },
        download() {
            this.downloading = true;
            this.$axios
                .get('/coupon/excel', {
                    responseType: 'blob',
                    params: { size: 10000 }
                })
                .then(res => {
                    console.log(res);
                    this.downloading = false;
                    const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(e => {
                    console.log(e);
                    this.downloading = false;
                    this.$message.error(e.error);
                });
        },
        operation1() {
            this.$notify({
                title: '提示',
                message: this.selection
            });
        },
        operation2() {
            this.$message('操作2');
        },
        deleteRow(row) {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/coupon/del/${row.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.getData();
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        this.$message.error(e.error);
                    }
                });
        }
    }
};
</script>
<style lang="less" scoped>
</style>
